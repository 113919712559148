import { Injectable } from "@angular/core";
import { waitForAsync } from "@angular/core/testing";
import { AlertController, PopoverController, ToastController } from "@ionic/angular";
import { PopoverTooltipPage } from "../pages/popover-tooltip/popover-tooltip.page";
import { getLabelOfLanguageCode } from "../shared/split-submodules/functions/functions";

export enum QRLogo {
  NOLOGO = "noLogo",
  GASTROLOGO = "gastrosLogo",
  OWNLOGO = "ownLogo",
}

export interface PaymentMethod {
  /**
   * The Name which will be shown to customers
   */
  name: string;

  /**
   * The Identifier to select the correct payment-Pipeling (e.g. 'credit', 'cash', ...)
   */
  option: string;

  /**
   * Whether this payment method can be used inhouse
   */
  inhouse: boolean;

  /**
   * Whether this payment method can be used outerhouse
   */
  outerhouse: boolean;
}

export interface OrderDish {
  id: number;
  name: string;
  price: number;
  kind: number;
  prices: number[];
  image: string;
  extras: any[];
  sorted: boolean;
  note: string;
  dropdown: any[];
  checkboxen: any[];
  categorie: number;
  count: number;
}

export interface Order {
  items: OrderDish[];
  payment: {
    option: string;
    name: string;
    payed: number;
  };
  table: {
    nr: number;
  };
  pickup?: boolean;
  delivery?: boolean;
  deliveryInformations: any;
}

@Injectable({
  providedIn: "root",
})
export class UtilService {

  private popoverToolTip: any;
  private allowedPopoverId: number = 0;
  
  constructor(
    private popoverController: PopoverController,
    private toastController: ToastController,
    private alertController: AlertController,
  ) {}

  async timeOut() {
    return new Promise((r) => setTimeout(r, 500));
  }

  async showPopOver(ev: MouseEvent, code:string){
    
    await this.dismissPopOver();

    const myId = this.allowedPopoverId;
    
    const tooltip = await this.popoverController.create({
      component: PopoverTooltipPage,
      event: ev,
      cssClass: 'tooltip-Split-fitcontent',
      showBackdrop:false,
      componentProps:{code:code},
      translucent: true
    });
    
    if (this.allowedPopoverId !== myId) {
      return;
    }
    
    tooltip.present().then(() => {
      this.popoverToolTip = tooltip;
      if (this.allowedPopoverId !== myId) {
        tooltip.dismiss();
      }
    });

  }

  /**
   * dismisses tooltips
   */
  async dismissPopOver(){
    this.allowedPopoverId++;
    if(this.popoverToolTip != undefined){
      this.popoverToolTip.dismiss();
    }
  }

  public async successAlert(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom',
      color: 'success',
    })
    toast.present();
  }

  public async failedAlert(message: string, duration: number = 3000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'bottom',
      color: 'danger',
    })
    toast.present();
  }

  public getLabelOfLanguageCode(languageCode: string) {
    return getLabelOfLanguageCode(languageCode);
  }

  public async confirmAlert(title: string, message: string, onConfirm: Function, onCancel?: Function) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          role: "cancel",
          text: "Abbrechen",
          handler: () => {
            if (onCancel === undefined)
              return;
            onCancel();
          }
        },
        {
          text: "Bestätigen",
          handler: () => {
            onConfirm();
          }
        }
      ]
    })
    alert.present();
  }

  public fixRounding(value, precision) {
    const power = Math.pow(10, precision || 0);
    return Math.round(value * power) / power;
}
}
