import { Injectable, SkipSelf } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ToastController } from '@ionic/angular';
import { element } from 'protractor';
import { finalize } from 'rxjs/operators';
import { AccountService } from './account.service';
import * as $ from 'jquery';
import { CheckoutSystem, CheckoutSystemApiService } from './api-services/checkout-system-api.service';
import { UtilService } from './util.service';
import { Product, ProductTag, ProductTagGroup } from 'src/app/shared/split-submodules/types/types';
import { Observable, Subscription } from 'rxjs';
import { dishesPerProductDocument } from '../shared/split-submodules/constants/constants';
import { LocalizationService } from './localization.service';
import { BehaviorSubject } from 'rxjs';
export class Dish {
  id: number = -1;
  name: string = "";
  description: string = "";
  allergens?: string[] = [];
  categoryId: number = -1;
  inhousePrice: number = 0;
  outerhousePrice: number = 0;
  inhouseTax: number = 0;
  outerhouseTax: number = 0;
  inhouseVisible: boolean = false;
  outerhouseVisible: boolean = false;
  offer: boolean = false;
  offerData?: any;
  opt: boolean = false;
  extraId: string = "";
  kind: number = 0;
  isFood: boolean = true;
  hasAlc?:number;
  img: string = "";
  articleNr: string;
  printer: string="";
  tags: number[] = [4];
  addData: {};
  extraData?: {
    minInhousePrice: number,
    minOuterhousePrice: number,
  }

  constructor(id?: number, categoryId?: number) {
    // this.id = id;

    if (id != undefined) {
      this.id = id;
    }

    if (categoryId != undefined) {
      this.categoryId = categoryId;
    }
  }

  public initTaxes(countryCode: string) {
    this.inhouseTax = this.getTaxrate(countryCode, true);
    this.outerhouseTax = this.getTaxrate(countryCode, false);
  }

  private getTaxrate(countryCode: string, inhouse: boolean) {
    // [
    //   [foodInhouse,   foodOuterhouse],
    //   [drinkInhouse, drinkOuterhouse],
    // ]
    let taxMap;

    switch (countryCode) {
      case "de":
      case "DE": taxMap = [
        [0.19, 0.07],
        [0.19, 0.19],
      ];
      break;
      case "au":
      case "AU": taxMap = [
        [0.10, 0.10],
        [0.20, 0.20],
      ];
      break;
      default: 
      taxMap = [
        [0.19, 0.07],
        [0.19, 0.19],
      ];
      break;
    }

    return taxMap[this.isFood ? 0 : 1][inhouse ? 0 : 1];
  }
}

export class Category {
  categoryImg:string = ""
  id: number;
  name: string = "";
  isFood: boolean = true;
  description: string = "";
  inhouseVisible: boolean = false;
  outerhouseVisible: boolean = false;
  addData?: any;
  hasAlc?:number;

  constructor(id: number) {
    this.id = id;
  }
}

export class Extra {
  id: string = "";
  extraCategories: ExtraCategory[] = [];
  description: string = "";
  img: string = "";
}

export class ExtraCategory {
  kind: number = 0;
  name: string = "";
  description: string = "";
  free?: number;
  minCount?: number;
  maxCount?: number;
  extraItems: ExtraItem[] = [];
}

export class ExtraItem {
  name: string = "";
  inhousePrice: number = 0;
  outerhousePrice: number = 0;
  inhouseTax: number = 0;
  outerhouseTax: number = 0;
  inhouseVisible: boolean = false;
  outerhouseVisible: boolean = false;
  isFood: boolean = true;
  articleNr: string;
  addData:{};

  public initTaxes(countryCode: string, isFood: boolean) {
    this.inhouseTax = this.getTaxrate(countryCode, true, isFood);
    this.outerhouseTax = this.getTaxrate(countryCode, false, isFood);
  }

  private getTaxrate(countryCode: string, inhouse: boolean, isFood: boolean) {
    // [
    //   [foodInhouse,   foodOuterhouse],
    //   [drinkInhouse, drinkOuterhouse],
    // ]
    let taxMap;

    switch (countryCode) {
      case "de":
      case "DE": taxMap = [
        [0.19, 0.07],
        [0.19, 0.19],
      ];
      break;
      case "au":
      case "AU": taxMap = [
        [0.10, 0.10],
        [0.20, 0.20],
      ];
      break;
      default: 
      taxMap = [
        [0.19, 0.07],
        [0.19, 0.19],
      ];
      break;
    }

    return taxMap[isFood ? 0 : 1][inhouse ? 0 : 1];
  }
}

export enum ExtraCategoryType {
  DROPDOWN = 0,
  CHECKBOX = 1,
  PLUSMINUS = 2,
  TEXTCOMPONENT = 3,
}

interface MenuData {
  id: string,
  categoryReference: AngularFirestoreDocument<firebase.default.firestore.DocumentData>,
  productDocIds: string[],
  data: {
    categories: any[],
    dishes: any[],
    extras: any[],
  },
  loadAllExtras: boolean,
  categorySubscription: Subscription,
  productSubscription: Subscription,
  loadedAllExtras?: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  loadedGastroMenus: MenuData[] = [];
  stagedChanges: boolean = false;
  menuSub: [Subscription] = [new Subscription]

  //This variable is a temporary id for new extras which they keep until they are saved to the database 
  private customExtraId: number = 0;

  public listenerForUpdateSecurity = [];

  // Holds an active Checkoutsystem api object. The checkoutsystem is only active if the flag for given checkoutsystem is set in the gastros collection "checkoutSystemApi" and the data is succefully initialized  
  private activeCheckoutSystem: any;

  productTags: ProductTag[] = [];
  productTagGroups: ProductTagGroup[] = [];
  public saveInProgress:Array<boolean> = [];

  //event to prevent overwrite from another user
//   private anOtherUserSavedBefore:Event = new Event('menuChanged')

  constructor(
    public accountService: AccountService,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    public checkoutSystemApiService: CheckoutSystemApiService,
    private localizationService: LocalizationService,
    public utilService: UtilService

  ) { 
    this.loadProductTags();
    this.afAuth.onAuthStateChanged(u => {
      this.loadedGastroMenus = [];
    })

    this.checkoutSystemApiService.getActiveApi().then((api: CheckoutSystem) => {
      this.activeCheckoutSystem = api;
    })
  }

  async loadProductTags() {
    const productTagCollection = await this.afs.collection('productTags').get().toPromise();
    const productTagDoc = productTagCollection.docs[0];
    if (productTagDoc.exists) {
      const data: {
        productTagGroups: ProductTagGroup[],
        productTags: ProductTag[],
      } = <any>productTagDoc.data();
      this.productTagGroups = data.productTagGroups;
      this.productTags = data.productTags;
    }
  }

  /**
   * Initializes the tags for a product. 
   * This has to be done in case the migration missed adding tags for a product.
   * @param product 
   */
  initTags(product: Product) {
    if (product.tags === undefined) {
        product.tags = [];
    }

    //Product tags are already initialized
    if (product.tags.length > 0) {
        return;
    }

    const foodTagId: number = 4;
    const drinkTagId: number = 5;
    const offerTagId: number = 3;

    product.tags.push(product.isFood ? foodTagId : drinkTagId);
    if (product.offer === true) {
        product.tags.push(offerTagId);
    }
  }

  setIsFoodOfDish(dish: any, newValue: boolean) {
    const foodTagId: number = 4;
    const drinkTagId: number = 5;

    dish.isFood = newValue;

    if (dish.tags === undefined) {
        dish.tags = [];
    }

    if (newValue === true) {
      //Remove potential DrinkTag and add FoodTag
      const drinkIndex = dish.tags.findIndex(elem => elem === drinkTagId);
      if (drinkIndex !== -1) {
        dish.tags.splice(drinkIndex, 1);
      }
      if (!dish.tags.includes(foodTagId)) {
        dish.tags.push(foodTagId);
      }
    } else {
      //Remove potential FoodTag and add DrinkTag
      const foodIndex = dish.tags.findIndex(elem => elem === foodTagId);
      if (foodIndex !== -1) {
        dish.tags.splice(foodIndex, 1);
      }
      if (!dish.tags.includes(drinkTagId)) {
        dish.tags.push(drinkTagId);
      }
    }
  }

  extraCategoryKindToString(kind: number) {
    switch (kind) {
      case 0: return "Dropdown";
      case 1: return "Checkbox";
      case 2: return "PlusMinus";
      case 3: return "Text-Komponente";
      default: return "Unknown Type";
    }
  }

  getCatsOfGastro(id: string): Category[] {
    let foundIndex = this.loadedGastroMenus.findIndex(elem => elem.id == id);
    if (foundIndex == -1) {
      this.loadMenuOfGastro(id);
      return [];
    } else {
      return this.loadedGastroMenus[foundIndex].data.categories;
    }
  }

  getCatOfGastro(gastroId: string, categoryId: number): Category {
    return this.getCatsOfGastro(gastroId).find(elem => elem.id == categoryId)
  }

  getDishesOfGastro(id: string): Dish[] {
    let foundIndex = this.loadedGastroMenus.findIndex(elem => elem.id == id);
    if (foundIndex == -1) {
      this.loadMenuOfGastro(id);
      return [];
    } else {
      return this.loadedGastroMenus[foundIndex].data.dishes;
    }
  }



  getDishOfGastro(gastroId: string, dishId: number): Dish {
    return this.getDishesOfGastro(gastroId).find(elem => elem.id == dishId);
  }

  getExtrasOfGastro(gastroId: string): Extra[] {
    let foundIndex = this.loadedGastroMenus.findIndex(elem => elem.id == gastroId);
    if (foundIndex == -1) {
      this.loadMenuOfGastro(gastroId);
      return [];
    } else {
      return this.loadedGastroMenus[foundIndex].data.extras;
    }
  }

  getExtraOfGastro(gastroId: string, extraId: string): Extra {
    return this.getExtrasOfGastro(gastroId).find(elem => elem.id == extraId);
  }

  getDishesOfCategory(id: string, categroyId: number): Dish[] {
    let foundIndex = this.loadedGastroMenus.findIndex(elem => elem.id == id);
    if (foundIndex == -1) {
      this.loadMenuOfGastro(id);
      return [];
    } else {
      const gastroDishes = this.loadedGastroMenus[foundIndex].data.dishes;
      return gastroDishes.filter(dish => dish.categoryId == categroyId);
    }
  }


  getOffersOfGastro(gastroId: string, offerCategory: string): Dish[] {
    let ret = [];
    let dishes = this.getDishesOfGastro(gastroId);

    dishes.forEach(dish => {
      if (dish.offer == true) {
        if (dish.offerData != undefined) {
          if (dish.offerData.category != undefined && dish.offerData.category == offerCategory) {
            ret.push(dish);
          }
        }
      }
    })

    return ret;
  }

  getOffersOfCategory(gastroId: string, categoryId: number): Dish[] {
    let dishes = this.getDishesOfGastro(gastroId);
    let ret = [];
    dishes.forEach(dish => {
      if (dish.offer == true) {
        if (dish.categoryId == categoryId) {
          ret.push(dish);
        }
      }
    })
    return ret;
  }

  getOffersOfCategoryAndOfferCategory(gastroId: string, categoryId: number, offerCategory: any): Dish[] {
    let categoryOffers = this.getOffersOfCategory(gastroId, categoryId);
    let ret = [];
    categoryOffers.forEach(dish => {
      if (dish.offerData != undefined) {
        if (dish.offerData.category == offerCategory.category) {
          ret.push(dish);
        }
      }
    })
    return ret;
  }

  loadMenuOfGastro(id: string) {
    return new Promise(resolve => {

      let loadingCategories = true;
      let loadingDishes = true;
      let initialLoad = true;

      let menuData: MenuData = this.loadedGastroMenus.find(elem => elem.id === id);

      //The menu is already loaded
      if (menuData !== undefined) {
        
        resolve(true);
        return;
      }
      
      const gastroRef = this.afs.collection('gastro').doc(id);

      if (menuData === undefined) {
        menuData = {
          id: id,
          categoryReference: undefined,
          
          productDocIds: [],
          data: {
            categories: [],
            dishes: [],
            extras: [],
          },
          loadAllExtras: false,
          categorySubscription: undefined,
          productSubscription: undefined,
        }
        this.loadedGastroMenus.push(menuData);
      }

      if (menuData.categorySubscription !== undefined)
        menuData.categorySubscription.unsubscribe();

      menuData.categorySubscription = gastroRef.collection('categories').snapshotChanges().subscribe(categoryCol => {
        if (this.stagedChanges === false || initialLoad) {
          for (const categoryDoc of categoryCol) {
            const data = categoryDoc.payload.doc.data();
            const docId = categoryDoc.payload.doc.id;

            menuData.data.categories = data.categories;
            menuData.categoryReference = gastroRef.collection('categories').doc(docId);
          }
        }

        loadingCategories = false;
        if (!loadingCategories && !loadingDishes) {
          initialLoad = false;
          resolve(true);
        }

      })

      if (menuData.productSubscription !== undefined)
        menuData.productSubscription.unsubscribe();

      menuData.productSubscription = gastroRef.collection('products').snapshotChanges().subscribe(productCol => {
        if (this.stagedChanges === false || initialLoad) {
          const dishes = [];
          const productDocIds = [];
          for (const productDoc of productCol) {
            const data = productDoc.payload.doc.data();
            const docId = productDoc.payload.doc.id;
            for (const dish of data.dishes) {
              dishes.push(dish);
            }
            productDocIds.push(docId);
            
            /* Soon to be deprecated */
            if (data.categories !== undefined) {
              menuData.categoryReference = gastroRef.collection('products').doc(docId);
              menuData.data.categories = data.categories;
            }
            /* Soon to be deprecated */
          }

          menuData.data.dishes = dishes;
          menuData.productDocIds = productDocIds;
        }

        loadingDishes = false;
        if (!loadingCategories && !loadingDishes) {
          initialLoad = false;
          resolve(true);
        }
      })
    })
  }

  loadExtraOfGastroWithDishId(gastroId: string, dishId: number) {
    const dish = this.getDishOfGastro(gastroId, dishId);
    this.loadExtraOfGastro(gastroId, dish.extraId);
  }

  loadExtraOfGastro(gastroId: string, extraId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.afs.collection('gastro').doc(gastroId).collection('dishExtras').doc(extraId).snapshotChanges().subscribe((extraDoc: any) => {
        const foundGastro = this.loadedGastroMenus.find(elem => elem.id == gastroId);
        let foundExtraIndex = foundGastro.data.extras.findIndex(elem => elem.id == extraId)
        if (foundExtraIndex == -1) {
          if (extraDoc.payload.data() != undefined) {
            foundGastro.data.extras.push(extraDoc.payload.data());
          }
        } else {
          if (this.stagedChanges == true) {
            //In case changes were already made dont overwrite current changes with incoming subscription data
            resolve(true);
            return
          } else {
            foundGastro.data.extras[foundExtraIndex] = extraDoc.payload.data();
          }
        }
        resolve(true);
      })
    })
  }

  loadAllExtrasOfGastro(gastroId: string): Promise<boolean> {
    return new Promise((resolve) => {
      let dishes = this.getDishesOfGastro(gastroId);
      let finishedDishes = 0;
      dishes.forEach(dish => {
        if (dish.opt == true) {
          this.loadExtraOfGastro(gastroId, dish.extraId).then(() => {
            finishedDishes++;
            if (finishedDishes == dishes.length) {
              resolve(true);
            }
          });
        } else {
          finishedDishes++;
          if (finishedDishes == dishes.length) {
            resolve(true);
          }
        }
      })
      let gastroIndex = this.loadedGastroMenus.findIndex(elem => elem.id == gastroId);
      if (gastroIndex != -1) {
        this.loadedGastroMenus[gastroIndex].loadedAllExtras = true;
      }
    })
  }

  areAllExtrasLoadedOfGastro(gastroId: string) {
    let gastroIndex = this.loadedGastroMenus.findIndex(elem => elem.id == gastroId);
    if (gastroIndex != -1) {
      return this.loadedGastroMenus[gastroIndex].loadedAllExtras;
    }
    return false;
  }

  getAvailableCategoryId(gastroId: string): number {
    let categories = this.getCatsOfGastro(gastroId);
    for (let i = 0; i < Number.MAX_SAFE_INTEGER; i++) {
      if (categories.find(elem => elem.id == i) == undefined)
        return i;
    }
  }

  getAvailableDishId(gastroId: string): number {
    let dishes = this.getDishesOfGastro(gastroId);
    for (let i = 0; i < Number.MAX_SAFE_INTEGER; i++) {
      if (dishes.find(elem => elem.id == i) == undefined)
        return i;
    }
  }

  gastroHasDishesWithoutCategory(gastroId: string): boolean {
    return this.getDishesOfGastro(gastroId).filter(elem => elem.categoryId == -1).length > 0;
  }

  async createNewExtra(gastroId: string) {
    this.customExtraId++;
    const extra = new Extra();
    extra.id = this.customExtraId.toString();
    const gastro = this.loadedGastroMenus.find(elem => elem.id == gastroId);
    gastro.data.extras.push(extra);
    this.stagedChanges = true;
    return extra;
  }

  deleteExtra(gastroId: string, extraId: string) {
    const dishes = this.getDishesOfGastro(gastroId);
    dishes.forEach(dish => {
      if (dish.extraId == extraId) {
        dish.opt = false;
        dish.extraId = "";
      }
    })
    this.stagedChanges = true;
    const gastro = this.loadedGastroMenus.find(elem => elem.id == gastroId)
    gastro.data.extras.splice(gastro.data.extras.findIndex(elem => elem.id == extraId), 1);
  }

  copyDishImg(fromGastroId: string, toGastroId: string, newDish: Dish) {
    const originPath = `/images/${fromGastroId}/${newDish.name}`
    const originRef = this.afStorage.ref(originPath);

    originRef.getDownloadURL().toPromise().then((url) => {
      // This can be downloaded directly:
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const file = xhr.response;

        const path = `/images/${toGastroId}/${newDish.name}`
        const ref = this.afStorage.ref(path);
        const task = this.afStorage.upload(path, file)
        const snapshot = task.snapshotChanges().pipe(
          finalize(async () => {
            newDish.img = await ref.getDownloadURL().toPromise()
          }),
        );

        snapshot.subscribe();
      };
      xhr.open('GET', url);
      xhr.send();
    })
      .catch((error) => {
        // Handle any errors
      });
  }

  dishesAndCategoryArraysToObject(dishes: any[], categories: any[]): { categories: any[], dishes: any[] } {
    let jsObjectDishes = [];
    dishes.forEach(dish => {
      jsObjectDishes.push(this.dishToObject(dish));
    })

    let jsObjectCategories = [];
    categories.forEach(cat => {
      jsObjectCategories.push(this.categoryToObject(cat));
    })
    const data = {
      categories: jsObjectCategories,
      dishes: jsObjectDishes,
    }
    return data;
  }

  /**
   * The Class Category can't be saved in Angular Firestore because it is a custom class. This method will cast a Category Object to a plain js Object
   * @param category the Category to be converted
   */
  categoryToObject(category: any): Object {
    let ret: any = {
      categoryImg: category.categoryImg,
      id: category.id,
      name: category.name,
      description: category.description,
      isFood: category.isFood,
	  hasAlc: category.hasAlc,
      inhouseVisible: category.inhouseVisible,
      outerhouseVisible: category.outerhouseVisible,
      printer: category.printer ? category.printer : "",
	  addData: category.addData,
    }

    delete category.empty

    if (category.isFood == undefined) {
      category.isFood = true
      ret.isFood = true
    }
    if (ret.categoryImg == undefined) {
      ret.categoryImg = "";
    }

	if (ret.hasAlc === undefined || ret.hasAlc === 0) {
		delete(ret.hasAlc)
	}

	if (ret.addData == undefined) {
		delete ret.addData
	}

    return ret;
  }

  /**
   * The Class Dish can't be saved in Angular Firestore because it is a custom class. This method will cast a Dish Object to a plain js Object
   * @param extra the Dish to be converted
   */
  dishToObject(dish: any): Object {
    let ret: any = {
      id: dish.id,
      name: dish.name,
      description: dish.description,
      allergens: dish.allergens ?? [],
      allergensConfirmed: dish.allergensConfirmed === undefined ? false : dish.allergensConfirmed,
      categoryId: dish.categoryId,
      inhousePrice: dish.inhousePrice,
      outerhousePrice: dish.outerhousePrice,
      inhouseTax: dish.inhouseTax,
      outerhouseTax: dish.outerhouseTax,
      inhouseVisible: dish.inhouseVisible,
      outerhouseVisible: dish.outerhouseVisible,
      offer: dish.offer,
      opt: dish.opt,
      extraId: dish.extraId,
      kind: dish.kind,
      isFood: dish.isFood,
	  hasAlc: dish.hasAlc,
      img: dish.img,
      articleNr: dish.articleNr,
      printer: dish.printer? dish.printer : "",
      tags: dish.tags === undefined ? [] : dish.tags,
      addData: dish.addData,
    }
    if (dish.extraData !== undefined) {
      ret.extraData = dish.extraData;
    }

    if (dish.articleNr == undefined || dish.articleNr == "") {
      delete ret.articleNr
    }
    if (dish.addData == undefined) {
      delete ret.addData
    }
    if (ret.extraId == undefined) {
      ret.extraId = "";
    }
    if (ret.isFood == undefined) {
      this.setIsFoodOfDish(ret, true);
    }
    if (dish.offerData != undefined) {
      ret.offerData = dish.offerData;
    }
	if (dish.hasAlc === undefined || dish.hasAlc === 0) {
		delete(ret.hasAlc)
	}
    if (dish.kind == undefined) {
      ret.kind = 0
    }
    if (dish.img == undefined) {
      ret.img = '';
    }
    if (dish.opt === false) {
      delete ret.extraData;
    }
    return ret;
  }

  /**
   * The Class Extra can't be saved in Angular Firestore because it is a custom class. This method will cast an extra Object to a plain js Object
   * @param extra the extra to be converted
   */
  extraToObject(extra: any): Object {
    let ret: any = {
      id: extra.id,
      description: extra.description,
      img: extra.img,
    }

    let extraCategoriesAsObject = [];
    extra.extraCategories.forEach(cat => {
      extraCategoriesAsObject.push(this.extraCatToObject(cat));
    })

    ret.extraCategories = extraCategoriesAsObject;

    return ret;
  }

  /**
   * The Class ExtraCategory can't be saved in Angular Firestore because it is a custom class. This method will cast an extraCategory Object to a plain js Object
   * @param extraCat the extraCategory to be converted
   */
  extraCatToObject(extraCat: any): Object {
    let ret: any = {
      addData: extraCat.addData,
      kind: extraCat.kind,
      name: extraCat.name,
      description: extraCat.description,
    }

    //for safetyreasons
    if (typeof extraCat.kind === 'string') {
      ret.kind = parseInt(extraCat.kind)
    }

    let extraItemsAsObject = [];
    extraCat.extraItems.forEach(item => {
      extraItemsAsObject.push(this.extraItemToObject(item));
    })
    ret.extraItems = extraItemsAsObject;

    if (extraCat.free != undefined) {
      ret.free = extraCat.free;
    }

    if (extraCat.minCount !== undefined) {
      ret.minCount = parseInt(extraCat.minCount);
    }

    if (extraCat.maxCount != undefined) {
      ret.maxCount = parseInt(extraCat.maxCount);
    }

    if (extraCat.addData == undefined) {
      delete ret.addData
    }

    return ret;
  }

  /**
   * The Class ExtraItem can't be saved in Angular Firestore because it is a custom class. This method will cast an extraItem Object to a plain js Object
   * @param extraItem the extraItem to be converted
   */
  extraItemToObject(extraItem: any): Object {
    let ret = {
      name: extraItem.name,
      inhousePrice: extraItem.inhousePrice,
      outerhousePrice: extraItem.outerhousePrice,
      inhouseTax: extraItem.inhouseTax,
      outerhouseTax: extraItem.outerhouseTax,
      inhouseVisible: extraItem.inhouseVisible,
      outerhouseVisible: extraItem.outerhouseVisible,
      isFood: extraItem.isFood,
      articleNr: extraItem.articleNr,
	  addData: extraItem.addData,
    }

    if (extraItem.articleNr == undefined || extraItem.articleNr == "") {
      delete ret.articleNr
    }

	if (extraItem.addData == undefined) {
		delete ret.addData
	}

    return ret;
  }

  /**
   * Adds .extraData to [dish] which includes the minimum price of any required extras
   * @param dish 
   * @param extra 
   */
  private calculateExtraMinPrices(dish: Dish, extra: Extra) {
    dish.extraData = {
      minInhousePrice: 0,
      minOuterhousePrice: 0,
    };

    for (const extraCategory of extra.extraCategories) {
      const extraItems = extraCategory.extraItems.slice();
      
      //Dropdown has always a required option
      if (extraCategory.kind === 0) {
        if (extraItems.length === 0) {
          continue;
        }

        //Add minInhousePrice for this ExtraCategory
        extraItems.sort((a, b) => a.inhousePrice - b.inhousePrice);
        dish.extraData.minInhousePrice += Number.parseFloat(extraItems[0].inhousePrice as any);

        //Add minOuterhousePrice for this ExtraCategory
        extraItems.sort((a, b) => a.outerhousePrice - b.outerhousePrice);
        dish.extraData.minOuterhousePrice += Number.parseFloat(extraItems[0].outerhousePrice as any);
        
        continue;
      } 
      // extraCategory has required options
      else if (extraCategory.minCount !== undefined) {
        //Add minInhousePrice for this ExtraCategory
        extraItems.sort((a, b) => a.inhousePrice - b.inhousePrice);
        switch (extraCategory.kind) {
          case 1: 
            for (let i = 0; i < extraCategory.minCount; i++) {
              if (i < extraCategory.free) {
                continue;
              }
              if (extraItems[i] !== undefined) {
                dish.extraData.minInhousePrice += Number.parseFloat(extraItems[i].inhousePrice as any);
              }
            }
            break;
          case 2: 
          if (extraItems.length > 0) {
              const payableItemAmount = Math.max(0, extraCategory.minCount - extraCategory.free)
              dish.extraData.minInhousePrice += Number.parseFloat(extraItems[0].inhousePrice as any) * payableItemAmount;
            }
            break;
          default: break;
        }

        //Add minOuterhousePrice for this ExtraCategory
        extraItems.sort((a, b) => a.outerhousePrice - b.outerhousePrice);
        switch (extraCategory.kind) {
          case 1: 
            for (let i = 0; i < extraCategory.minCount; i++) {
              if (i < extraCategory.free) {
                continue;
              }
              if (extraItems[i] !== undefined) {
                dish.extraData.minOuterhousePrice += Number.parseFloat(extraItems[i].outerhousePrice as any);
              }
            }
            break;
          case 2: 
            if (extraItems.length > 0) {
              const payableItemAmount = Math.max(0, extraCategory.minCount - extraCategory.free)
              dish.extraData.minOuterhousePrice += Number.parseFloat(extraItems[0].outerhousePrice as any) * payableItemAmount;
            }
            break;
          default: break;
        }
      }
    }
  }

  /**
   * This method will save all changes in products and dishExtras which were made. It will send a success or failure message.
   */
  saveChanges(): void {

    this.saveInProgress.push(true)

    this.loadedGastroMenus.forEach(menu => {
      const gastroId = menu.id
      Promise.all(menu.data.extras.map(extra => {
        //Delete empty articleNr fields
        extra.extraCategories.forEach(element => {
          element.extraItems.forEach(extraitem => {
            if (extraitem.articleNr == "") {
              delete extraitem.articleNr;
            }
          });
        });

        //Set minPrice for the dish

        const dishesOfExtra = menu.data.dishes.filter(dish => dish.extraId === extra.id);
        for (const dish of dishesOfExtra) {
          this.calculateExtraMinPrices(dish, extra);
        }

        if (extra.id.match(new RegExp("^[0-9]+$")) && parseInt(extra.id) <= this.customExtraId) {
          //The extra was created in this session
          return this.afs.collection('gastro').doc(gastroId).collection('dishExtras').add(this.extraToObject(extra)).then(docRef => {
            this.afs.collection('gastro').doc(gastroId).collection('dishExtras').doc(docRef.id).update({
              id: docRef.id,
            })
            for (const dish of dishesOfExtra) {
              dish.extraId = docRef.id;
            }
            const languageCodes = this.localizationService.getCurrentLanguages();
            for (const languageCode of languageCodes) {
              const menuTranslation = this.localizationService.getMenuLanguage(languageCode);
              const extraTranslation = menuTranslation.extras[extra.id]
              if (extraTranslation !== undefined) {
                const copy = JSON.parse(JSON.stringify(extraTranslation));
                menuTranslation.extras[docRef.id] = copy;
                delete menuTranslation.extras[extra.id];
              }
            }
            this.localizationService.saveAllLanguages();
            extra.id = docRef.id;
          })
        } else {
          //The extra already existed
          return this.afs.collection('gastro').doc(gastroId).collection('dishExtras').doc(extra.id).set(this.extraToObject(extra));
        }
      })).then(
        (success) => {

          //Extras were successfully saved. Continue to save Products and Categories
          const promises = [];
          const gastroRef = this.afs.collection('gastro').doc(gastroId).ref;
          
          const data = this.dishesAndCategoryArraysToObject(menu.data.dishes, menu.data.categories);
          // const dishes = data.dishes.sort((a: any, b: any) => a.id - b.id);
          const dishes = data.dishes;

          //Iterate through the already existing documents and fill them with data
          for (const productDocId of menu.productDocIds) {
            const dishesOfDoc = [];
            while (dishesOfDoc.length < dishesPerProductDocument) {
              const dish = dishes.shift();
              if (dish === undefined) {
                break;
              }
              dishesOfDoc.push(dish);
            }

            if (dishesOfDoc.length > 0) {
              // The current document can be filled with products
              promises.push(gastroRef.collection('products').doc(productDocId).update({
                dishes: dishesOfDoc,
              }));
            } else {
              //If the productdoc is empty dont delete it
              if (menu.productDocIds.findIndex(elem => productDocId === elem) === 0) {
                continue;
              }
              //There are not enough products to fill this document
              promises.push(gastroRef.collection('products').doc(productDocId).delete());
            }
          }

          //There are still products that haven't been added to any document
          while (dishes.length > 0) {
            const dishesOfDoc = [];
            while (dishesOfDoc.length < dishesPerProductDocument) {
              const dish = dishes.shift();
              if (dish === undefined) {
                break;
              }
              dishesOfDoc.push(dish);
            }

            promises.push(gastroRef.collection('products').add({
              dishes: dishesOfDoc,
            }))
          }

          promises.push(menu.categoryReference.update({categories: data.categories}));

          Promise.all(promises).then(
            () => {
              //Success
              this.utilService.successAlert('Änderungen wurden erfolgreich gesichert.')
              setTimeout(() => {
                this.saveInProgress.shift()
              }, 1000)
              this.stagedChanges = false;
            },
            (error) => {
              //Error saving Products or Categories
              console.error(error)
              this.utilService.failedAlert('Änderungen konnten nicht gesichert werden.');
            }
          );
        },
        (error) => {
          //Error saving extras
          console.error(error)
          this.utilService.failedAlert('Änderungen konnten nicht gesichert werden.');
        }
      )
    })
  }

  public getLoadedMenus() {
    return this.loadedGastroMenus;
  }

  loadMenusOfGastros(): void {
    this.loadedGastroMenus = [];
    this.menuSub?.forEach((e) => { e.unsubscribe() })
    this.accountService.loadGastros.subscribe((doc: any) => {
      if (doc[0] != undefined) {
		this.saveInProgress.push(true)
        doc[0].gastros.forEach(gastroId => {
          this.loadMenuOfGastro(gastroId);
          //this is in case that another user saves changes 
        })
        setTimeout(() => {
          this.saveInProgress.shift();
        }, 1000)
      }
    })
  }


  /**
   * Clears the menu of given gastro.
   * @param gastroId 
   * @returns A Promise which resolves when the menu is cleared 
   */
  clearMenu(gastroId: string): Promise<[unknown, unknown]> {
    // Clears all categories and products
    const productPromise = new Promise((resolve: any, reject: any) => {
      Promise.all([
        this.afs.collection("gastro").doc(gastroId).collection("products").get().toPromise().then((querySnapshot: any) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref.delete();
          });
        }),
        this.afs.collection("gastro").doc(gastroId).collection("categories").get().toPromise().then((querySnapshot: any) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref.delete();
          });
        }),
      ]).then(
        (success) => {
          resolve();
        },
        (error) => {
          console.log(error);
          reject();
        }
      )
    });

    // Clears all dishExtras
    const dishesExtrasPromise = new Promise((resolve: any, reject: any) => {
      this.afs
        .collection("gastro")
        .doc(gastroId)
        .collection("dishExtras")
        .get()
        .toPromise()
        .then((querySnapshot: any) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref.delete();
          });
          resolve();
        })
        .catch((reason: any) => {
          console.log(reason);
          reject();
        });
    });
    return Promise.all([productPromise, dishesExtrasPromise]);
  }


   /**
   * Imports the menu of a given active externe Checkoutsystem of a gastro.
   * @param gastroId 
   * @returns A Promise which resolves when the menu succefully imported
   */
    async importCheckoutSystemData(gastroId: string): Promise<void> {
      if (this.activeCheckoutSystem !== undefined) {
        const categories = this.activeCheckoutSystem.getCategories();
        const products = this.activeCheckoutSystem.getProducts();
        if (categories.length > 0 && products.length > 0) {
          let promises = [];
          products.forEach((product: any) => {
            if (product.extraId) {
              const extraPromise = new Promise((resolve: any) => {
                const extra = this.activeCheckoutSystem.getExtra(product.extraId);
                extra.id = product.extraId
                this.afs
                  .collection("gastro")
                  .doc(gastroId)
                  .collection("dishExtras")
                  .doc(product.extraId).set(extra)
                  .then(() => {
                    resolve();
                  });
              });
              promises.push(extraPromise);
            }
          });
          Promise.all(promises).then(() => {
            // Only add all new categories and products when new extraIds are added to each product
            this.afs
              .collection("gastro")
              .doc(gastroId)
              .collection("products")
              .add({ categories: categories, dishes: products })
              .then(async () => {
                const message = "Daten sind erfolgreich importiert";
                console.log(message);
                this.utilService.successAlert(message);
              });
          });
        } else {
          const message = "Daten konto nicht importiert werden";
          console.log(message);
          this.utilService.failedAlert(message);
        }
      } else {
        const message = "Kein externe Kassesystem verfügbar";
        console.log(message);

        this.utilService.failedAlert(message);
      }
    }

	/**
	 * This function changes all tax rates of a category and its belonging extra items.
	 * This includes inhouse and takeout tax rates.
	 * For german food tax rates are inhouse 7% and takeout 7% (just until end of 2023).
	 * For german drinks tax rates are inhouse 19% and takeout 19%.
	 * For austrian food tax rates are inhouse 10% and takeout 20%.
	 * For austrian drinks tax rates are inhouse 20% and takeout 20%.
	 * @param categoryId
	 * @param gastroId
	 */
	async changeTaxRate(category: Category, gastroId: string): Promise<void> {
		const gastro = await this.afs.collection("gastro").doc(gastroId).get().toPromise();
		const country = await gastro.data()['country'];
		const dishes = this.getDishesOfCategory(gastroId, category.id)
		for (const dish of dishes) {
			if (dish.categoryId === category.id) {
				if (country === 'DE') {
					if (dish.isFood) {
						dish.inhouseTax = 0.19;
						dish.outerhouseTax = 0.07;
					} else {
						dish.inhouseTax = 0.19;
						dish.outerhouseTax = 0.19;
					}
				} else if (country === 'AT') {
					if (dish.isFood) {
						dish.inhouseTax = 0.10;
						dish.outerhouseTax = 0.20;
					} else {
						dish.inhouseTax = 0.20;
						dish.outerhouseTax = 0.20;
					}
				} else {
					if (dish.isFood) {
						dish.inhouseTax = 0.19;
						dish.outerhouseTax = 0.07;
					} else {
						dish.inhouseTax = 0.19;
						dish.outerhouseTax = 0.19;
					}
				}

				if (dish.extraId) {
					let gastroIndex = this.loadedGastroMenus.findIndex(elem => elem.id == gastroId);
					if ( this.loadedGastroMenus[gastroIndex].loadedAllExtras == true) {
						this.changeTaxRateOfExtraItem(dish.extraId, country, gastroId, dish.isFood, gastroIndex);
					} else {
						await this.loadAllExtrasOfGastro(gastroId);
						this.changeTaxRateOfExtraItem(dish.extraId, country, gastroId, dish.isFood, gastroIndex);
					}
				}
			}
		}
	}

	  /**
	   * This function changes the tax rate of an extra item.
	   * This includes inhouse and takeout tax rates.
	   * For german food tax rates are inhouse 7% and takeout 7% (just until end of 2023).
	   * For german drinks tax rates are inhouse 19% and takeout 19%.
	   * For austrian food tax rates are inhouse 10% and takeout 20%.
	   * For austrian drinks tax rates are inhouse 20% and takeout 20%.
	   * @param dishExtraId
	   * @param country
	   */
	  changeTaxRateOfExtraItem(dishExtraId:string, country:string, gastroId:string, isFood, gastroIndex): void {
		const extra = this.getExtraOfGastro(gastroId, dishExtraId)
		if (extra === undefined) {
			return;
		}
		for (const categorie of extra.extraCategories) {
			for (let itemWithType of categorie.extraItems) {
				const item:any = itemWithType as any
				if (country === 'DE') {
					if (isFood) {
						item.inhouseTax = 0.19;
						item.outerhouseTax = 0.19;
					} else {
						item.inhouseTax = 0.19;
						item.outerhouseTax = 0.19;
					}
				} else if (country === 'AT') {
					if (isFood) {
						item.inhouseTax = 0.10;
						item.outerhouseTax = 0.20;
					} else {
						item.inhouseTax = 0.20;
						item.outerhouseTax = 0.20;
					}
				} else {
					if (isFood) {
						item.inhouseTax = 0.19;
						item.outerhouseTax = 0.19;
					} else {
						item.inhouseTax = 0.19;
						item.outerhouseTax = 0.19;
					}
				}
			}
		}

		this.loadedGastroMenus[gastroIndex].data.extras[0] = extra
	  }

}
